.vertical-centered-box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vertical-centered-box:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}
.vertical-centered-box .content {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation: fadein 1s 0.8999999999999999s linear forwards;
  -moz-animation: fadein 1s 0.8999999999999999s linear forwards;
  -o-animation: fadein 1s 0.8999999999999999s linear forwards;
  animation: fadein 1s 0.8999999999999999s linear forwards;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 0;
  opacity: 0;
}
.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 25px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  overflow: hidden;
  -webkit-transform-origin: 25px 25px;
  -moz-transform-origin: 25px 25px;
  -ms-transform-origin: 25px 25px;
  -o-transform-origin: 25px 25px;
  transform-origin: 25px 25px;
  -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
  mix-blend-mode: hard-light;
  opacity: 0.8;
}
.loader-line-mask .loader-line {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.loader-line-mask.one {
  -webkit-animation: rotate 1.2s infinite linear;
  -moz-animation: rotate 1.2s infinite linear;
  -o-animation: rotate 1.2s infinite linear;
  animation: rotate 1.2s infinite linear;
}
.loader-line-mask.one .loader-line {
  box-shadow: inset 0 0 0 8px #60fc0b;
}
.loader-line-mask.two {
  -webkit-animation: rotate 1s 0.3s infinite linear;
  -moz-animation: rotate 1s 0.3s infinite linear;
  -o-animation: rotate 1s 0.3s infinite linear;
  animation: rotate 1s 0.3s infinite linear;
}
.loader-line-mask.two .loader-line {
  box-shadow: inset 0 0 0 8px #f80928;
}
.loader-line-mask.three {
  -webkit-animation: rotate 1.2s 0.6s infinite linear;
  -moz-animation: rotate 1.2s 0.6s infinite linear;
  -o-animation: rotate 1.2s 0.6s infinite linear;
  animation: rotate 1.2s 0.6s infinite linear;
}
.loader-line-mask.three .loader-line {
  box-shadow: inset 0 0 0 8px #f8cf09;
}
.loader-line-mask.four {
  -webkit-animation: rotate 1s 0.7999999999999999s infinite linear;
  -moz-animation: rotate 1s 0.7999999999999999s infinite linear;
  -o-animation: rotate 1s 0.7999999999999999s infinite linear;
  animation: rotate 1s 0.7999999999999999s infinite linear;
}
.loader-line-mask.four .loader-line {
  box-shadow: inset 0 0 0 8px #09ecf6;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
[not-existing] {
  zoom: 1;
}
