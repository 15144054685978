@import './styles/loader.css';

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic.eot');
  src: url('./assets/fonts/CocoGothic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/CocoGothic.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic.woff') format('woff'),
    url('./assets/fonts/CocoGothic.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic.svg#CocoGothic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-FatItalic.eot');
  src: url('./assets/fonts/CocoGothic-FatItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-FatItalic.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-FatItalic.woff') format('woff'),
    url('./assets/fonts/CocoGothic-FatItalic.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-FatItalic.svg#CocoGothic-FatItalic')
      format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-Fat.eot');
  src: url('./assets/fonts/CocoGothic-Fat.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-Fat.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-Fat.woff') format('woff'),
    url('./assets/fonts/CocoGothic-Fat.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-Fat.svg#CocoGothic-Fat') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-Italic.eot');
  src: url('./assets/fonts/CocoGothic-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-Italic.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-Italic.woff') format('woff'),
    url('./assets/fonts/CocoGothic-Italic.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-Italic.svg#CocoGothic-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-BoldItalic.eot');
  src: url('./assets/fonts/CocoGothic-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-BoldItalic.woff') format('woff'),
    url('./assets/fonts/CocoGothic-BoldItalic.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-BoldItalic.svg#CocoGothic-BoldItalic')
      format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-Heavy.eot');
  src: url('./assets/fonts/CocoGothic-Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-Heavy.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-Heavy.woff') format('woff'),
    url('./assets/fonts/CocoGothic-Heavy.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-Heavy.svg#CocoGothic-Heavy') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-HeavyItalic.eot');
  src: url('./assets/fonts/CocoGothic-HeavyItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-HeavyItalic.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-HeavyItalic.woff') format('woff'),
    url('./assets/fonts/CocoGothic-HeavyItalic.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-HeavyItalic.svg#CocoGothic-HeavyItalic')
      format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-Bold.eot');
  src: url('./assets/fonts/CocoGothic-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-Bold.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-Bold.woff') format('woff'),
    url('./assets/fonts/CocoGothic-Bold.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-Bold.svg#CocoGothic-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-LightItalic.eot');
  src: url('./assets/fonts/CocoGothic-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-LightItalic.woff') format('woff'),
    url('./assets/fonts/CocoGothic-LightItalic.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-LightItalic.svg#CocoGothic-LightItalic')
      format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-UltraLightItalic.eot');
  src: url('./assets/fonts/CocoGothic-UltraLightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-UltraLightItalic.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-UltraLightItalic.woff') format('woff'),
    url('./assets/fonts/CocoGothic-UltraLightItalic.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-UltraLightItalic.svg#CocoGothic-UltraLightItalic')
      format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Coco Gothic';
  font-display: block;
  src: url('./assets/fonts/CocoGothic-Light.eot');
  src: url('./assets/fonts/CocoGothic-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/CocoGothic-Light.woff2') format('woff2'),
    url('./assets/fonts/CocoGothic-Light.woff') format('woff'),
    url('./assets/fonts/CocoGothic-Light.ttf') format('truetype'),
    url('./assets/fonts/CocoGothic-Light.svg#CocoGothic-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

body {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.swal2-container {
  z-index: 1300 !important;
}